import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Heading from 'components/shared/heading';
import styles from './comparison-block.module.scss';

import shape from './images/shape.svg';
import CheckIcon from './images/check.inline.svg';
import UncheckIcon from './images/uncheck.inline.svg';

const cx = classNames.bind(styles);

const ComparisonBlock = (props) => {
  const {
    title,
    columnlefttitle,
    columnleft: columnLeftItems,
    columnrighttitle,
    columnright: columnRightItems,
    background,
  } = props;

  const getItems = (items) => (
    <ul className={cx('list')}>
      {items.map(({ text, checked = false }, index) => (
        <li className={cx({ checked })} key={index}>
          {checked ? <CheckIcon /> : <UncheckIcon />}
          {text}
        </li>
      ))}
    </ul>
  );

  return (
    <section className={cx('wrapper', background)}>
      <div className={cx('container')}>
        <Heading className={cx('title')} size="xl" tag="h2">
          {title}
        </Heading>

        <div className={cx('content')}>
          <div className={cx('left')}>
            <Heading
              className={cx('content-title')}
              tag="h4"
              size="lg"
              innerHTML={columnlefttitle}
            />

            {getItems(columnLeftItems)}
          </div>
          <div className={cx('right')}>
            <Heading
              className={cx('content-title')}
              tag="h4"
              size="lg"
              innerHTML={columnrighttitle}
            />

            {getItems(columnRightItems)}
          </div>
        </div>

        <img className={cx('shape')} src={shape} alt="" loading="lazy" aria-hidden />
      </div>
    </section>
  );
};

ComparisonBlock.propTypes = {
  title: PropTypes.string.isRequired,
  columnlefttitle: PropTypes.string.isRequired,
  columnleft: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    })
  ).isRequired,
  columnrighttitle: PropTypes.string.isRequired,
  columnright: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  background: PropTypes.string.isRequired,
};

export default ComparisonBlock;
