/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from 'react';
import { graphql } from 'gatsby';
import parse, { attributesToProps } from 'html-react-parser';

import useImageGallery from 'hooks/use-image-gallery';
import useIntersectionObserver from 'hooks/use-intersection-observer';

import LayoutMain from 'layouts/layout-main';
import Subscribe from 'components/shared/subscribe';
import Hero from 'components/pages/blog-post/hero';
import Content from 'components/shared/content';
import ReadMore from 'components/pages/blog-post/read-more';
import Comments from 'components/pages/blog-post/comments';
import ModalDownloadForm from 'components/shared/modal-download-form';
import HighlightedList from 'components/lazy-blocks/highlighted-list';
import CompactTitleAndVideo from 'components/lazy-blocks/compact-title-and-video';
import PdfBannerWithButton from 'components/lazy-blocks/pdf-banner-with-button';
import ComparisonBlock from 'components/lazy-blocks/comparison-block';
import HubspotFormWrapper from 'components/lazy-blocks/hubspot-form-wrapper';
import BannerWithButton from 'components/lazy-blocks/banner-with-button';

import 'components/lazy-blocks/comprasion-table/comprasion-table.scss';
import 'components/lazy-blocks/image-gallery/image-gallery.scss';

const BlogPost = ({ data: { wpPost: data }, pageContext, location }) => {
  useImageGallery();

  useIntersectionObserver('.use-intersection-observer', {
    rootMargin: '200px',
  });

  // https://github.com/remarkablemark/html-react-parser#htmlparser2
  // The library does parsing on client side differently from server side
  // it results in having a need of passing htmlparser2 to adjust behavior
  // according to the client side behavior
  const reactedContent = parse(pageContext.content, {
    htmlparser2: {
      lowerCaseAttributeNames: true,
    },
    // eslint-disable-next-line consistent-return,react/no-unstable-nested-components
    replace: (domNode) => {
      const props = attributesToProps(domNode.attribs);

      if (domNode.type === 'tag') {
        switch (domNode.name) {
          case 'highlightedlist':
            const items = JSON.parse(props.items);
            return <HighlightedList title={props.title} items={items} />;
          case 'compacttitleandvideo':
            const videoAutoplay = !!Number(props.videoautoplay);
            const videobackgroundimage = JSON.parse(props.videobackgroundimage);
            return (
              <CompactTitleAndVideo
                title={props.title}
                description={props.description}
                quote={props.quote}
                video={props.video}
                isVideoAutoplay={videoAutoplay}
                videoBackgroundImage={videobackgroundimage}
              />
            );
          case 'pdfbannerwithbutton':
            return (
              <PdfBannerWithButton
                title={props.title}
                description={props.description}
                url={props.url}
                buttonText={props.buttontext}
              />
            );
          case 'comparisonblock':
            const columnLeftTitle = JSON.parse(props.columnlefttitle);
            const columnLeft = JSON.parse(props.columnleft);
            const columnRight = JSON.parse(props.columnright);
            const columnRightTitle = JSON.parse(props.columnrighttitle);
            return (
              <ComparisonBlock
                title={props.title}
                columnlefttitle={columnLeftTitle}
                columnleft={columnLeft}
                columnrighttitle={columnRightTitle}
                columnright={columnRight}
                background={props.background}
              />
            );
          case 'hubspotform':
            return (
              <HubspotFormWrapper
                title={props.title}
                description={props.description}
                isCompact={props['is-compact']}
                formId={props['form-id']}
                id={props.id}
              />
            );
          case 'bannerwithbutton':
            return (
              <BannerWithButton
                title={props.title}
                description={props.description}
                buttonText={props['button-text']}
                buttonUrl={props['button-url']}
                isCompact={props['is-compact']}
                background={props.background}
              />
            );
          default:
            return undefined;
        }
      }
    },
  });

  return (
    <LayoutMain
      seo={{ ...data.seo, opengraphImage: data.acf.opengraphImage, date: pageContext.date }}
      footerTheme="with-border"
      withBanner={pageContext.showBanner}
      withBottomOffset
      pageContext={pageContext}
      location={location}
    >
      <Hero
        title={data.title}
        authorName={data.author.node.name}
        authorAvatar={data.author.node.acf.avatar.localFile.childImageSharp}
        date={data.date}
        categories={data.categories.nodes}
      />
      <Content content={reactedContent} uri={data.uri} extraLinks={data.acf.extraLinks} />
      <Comments
        config={{
          identifier: data.id,
          title: data.title,
        }}
      />
      <Subscribe />
      <ReadMore items={data.acf.readMore} />

      {data.acf.fileForDownloading && (
        <ModalDownloadForm
          id={data.acf.fileForDownloading.id}
          postId={data.acf.fileForDownloading.databaseId}
          fileUrl={data.acf.fileForDownloading.acf.file.guid}
          title={data.acf.fileForDownloading.acf.title}
          description={data.acf.fileForDownloading.acf.description}
        />
      )}
    </LayoutMain>
  );
};

export default BlogPost;

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      uri
      date(formatString: "MMMM D, YYYY")
      author {
        node {
          name
          acf {
            avatar {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 40, width: 40, transformOptions: { cropFocus: CENTER })
                }
              }
            }
          }
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      acf {
        opengraphImage: image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 630, width: 1200, transformOptions: { cropFocus: CENTER })
            }
          }
        }
        readMore {
          post {
            ... on WpPost {
              title
              uri
              categories {
                nodes {
                  name
                }
              }
              acf {
                mediumImage: image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 360)
                    }
                  }
                }
                defaultImage: image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 280)
                    }
                  }
                }
                description
              }
            }
          }
        }
        fileForDownloading {
          ... on WpFileDownloading {
            id
            databaseId
            acf {
              title
              description
              file {
                guid
              }
            }
          }
        }
        extraLinks {
          url
          linkText
        }
      }
      ...wpPostSeo
    }
  }
`;
