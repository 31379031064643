import React from 'react';
import PropTypes from 'prop-types';
import { Disqus } from 'gatsby-plugin-disqus';
import classNames from 'classnames/bind';
import { useInView } from 'react-intersection-observer';

import styles from './comments.module.scss';

const cx = classNames.bind(styles);

const Comments = ({ config }) => {
  const [sectionRef, inView] = useInView({ triggerOnce: true, rootMargin: '300px' });
  return (
    <section ref={sectionRef} className={cx('wrapper')}>
      {inView && (
        <div className={cx('container', 'inner')}>
          <Disqus config={config} />
        </div>
      )}
    </section>
  );
};

Comments.propTypes = {
  config: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Comments;
