import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';
import MainContext from 'context/main';
import Link from 'components/shared/link';

import Heading from 'components/shared/heading';
import styles from './hero.module.scss';

const cx = classNames.bind(styles);

const Hero = ({ title, authorName, authorAvatar, date, categories }) => {
  const { isTableOfContent } = useContext(MainContext);

  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <div className={cx('content', { isTableOfContent })}>
          {!!categories?.length &&
            !!categories.filter(({ name }) => name !== 'Uncategorized').length && (
              <div className={cx('categories')}>
                {categories.map(({ name, slug }, index) => (
                  <Link className={cx('category')} to={`/blog/${slug}`} key={index}>
                    {name}
                  </Link>
                ))}
              </div>
            )}
          <Heading className={cx('title')}>{title}</Heading>
          <div className={cx('footer')}>
            <div className={cx('author')}>
              <GatsbyImage
                className={cx('avatar')}
                loading="eager"
                image={getImage(authorAvatar)}
                alt={authorName}
              />
              <div>
                <span className={cx('name')}>{authorName}</span>
                <span className={cx('date', 'xxs-visible')}>{date}</span>
              </div>
            </div>
            <span className={cx('date', 'xxs-hidden')}>{date}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  authorAvatar: PropTypes.objectOf(PropTypes.any).isRequired,
  date: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Hero;
