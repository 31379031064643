import { useEffect } from 'react';

const WRAPPER = 'image-gallery';
const SLIDER_TRACK = 'image-gallery-slider-track';
const SLIDER_IMAGE = 'image-gallery-image__item';
const SLIDER_IMAGE_ACTIVE = 'image-gallery-image__item_active';
const SLIDER_ITEMS_WRAPPER = 'image-gallery-items-wrapper';
const SLIDER_ITEM = 'image-gallery-items__item';
const SLIDER_ITEM_ACTIVE = 'image-gallery-items__item_active';
const SLIDER_BUTTON = 'image-gallery-slider__button';
const SLIDER_BUTTON_LEFT = 'image-gallery-slider__button_left';
const SLIDER_BUTTON_RIGHT = 'image-gallery-slider__button_right';

let slidesToShow;
let positionSliderTrack = 0;

const handleSliderTrack = () => {
  const sections = document.querySelectorAll(`.${WRAPPER}`);

  sections.forEach((section) => {
    const sliderTrack = section.querySelector(`.${SLIDER_TRACK}`);
    const itemsWrapper = section.querySelector(`.${SLIDER_ITEMS_WRAPPER}`);
    const itemsLength = section.querySelectorAll(`.${SLIDER_ITEM}`).length;

    sliderTrack.style.left = 0;

    if (itemsLength <= slidesToShow) {
      itemsWrapper.classList.add('hide-buttons');
    } else {
      itemsWrapper.classList.remove('hide-buttons');
    }
  });
};

const handleSlidesToShow = () => {
  const width = window.innerWidth;
  const slidesToShowXl = 7;
  const slidesToShowMd = 6;
  const slidesToShowSm = 4;
  const slidesToShowXss = 3;

  if (width <= 1023.98 && width > 767.98) {
    slidesToShow = slidesToShowMd;
  } else if (width <= 767.98 && width > 413.98) {
    slidesToShow = slidesToShowSm;
  } else if (width <= 413.98) {
    slidesToShow = slidesToShowXss;
  } else {
    slidesToShow = slidesToShowXl;
  }
};

const setSliderSettings = () => {
  handleSlidesToShow();
  handleSliderTrack();
};

const setActiveItem = ({ currentTarget, activeSliderIndex }) => {
  const wrapper = currentTarget.closest(`.${WRAPPER}`);
  const sliderItemActive = wrapper.querySelector(`.${SLIDER_ITEM_ACTIVE}`);
  const sliderItems = wrapper.querySelectorAll(`.${SLIDER_ITEM}`);
  const imageItems = wrapper.querySelectorAll(`.${SLIDER_IMAGE}`);

  if (currentTarget !== sliderItemActive) {
    sliderItemActive.classList.remove(SLIDER_ITEM_ACTIVE);

    sliderItems.forEach((item) => {
      if (activeSliderIndex === item.getAttribute('slider-index')) {
        item.classList.add(SLIDER_ITEM_ACTIVE);
      } else {
        item.classList.remove(SLIDER_ITEM_ACTIVE);
      }
    });

    imageItems.forEach((item) => {
      if (activeSliderIndex === item.getAttribute('slider-index')) {
        item.classList.add(SLIDER_IMAGE_ACTIVE);
      } else {
        item.classList.remove(SLIDER_IMAGE_ACTIVE);
      }
    });
  }
};

const handleItemClick = ({ currentTarget }) =>
  setActiveItem({
    currentTarget,
    activeSliderIndex: currentTarget.getAttribute('slider-index'),
  });

const handleButtonClick = ({ currentTarget }) => {
  const wrapper = currentTarget.closest(`.${WRAPPER}`);
  const itemWidth = wrapper.querySelector(`.${SLIDER_ITEM}`).offsetWidth + 20;
  const itemsLength = wrapper.querySelectorAll(`.${SLIDER_ITEM}`).length;
  const sliderTrack = wrapper.querySelector(`.${SLIDER_TRACK}`);

  const buttonLeft = currentTarget.classList.contains(SLIDER_BUTTON_LEFT);
  const buttonRight = currentTarget.classList.contains(SLIDER_BUTTON_RIGHT);

  const positionSliderTrackEnd = itemWidth * (itemsLength - slidesToShow);

  positionSliderTrack = sliderTrack.offsetLeft;

  if (buttonLeft) {
    if (positionSliderTrack === 0) {
      positionSliderTrack = -positionSliderTrackEnd;
    } else {
      positionSliderTrack += itemWidth;
    }
  }

  if (buttonRight) {
    if (positionSliderTrack === -positionSliderTrackEnd) {
      positionSliderTrack = 0;
    } else {
      positionSliderTrack -= itemWidth;
    }
  }

  sliderTrack.style.left = `${positionSliderTrack}px`;
};

export default function useImageGallery() {
  useEffect(() => {
    const items = document.querySelectorAll(`.${SLIDER_ITEM}`);
    const buttons = document.querySelectorAll(`.${SLIDER_BUTTON}`);

    setSliderSettings();

    items.forEach((item) => item.addEventListener('click', handleItemClick));
    buttons.forEach((item) => item.addEventListener('click', handleButtonClick));
    window.addEventListener('resize', setSliderSettings);

    return () => {
      items.forEach((item) => item.removeEventListener('click', handleItemClick));
      buttons.forEach((item) => item.removeEventListener('click', handleButtonClick));
      window.removeEventListener('resize', setSliderSettings);
    };
  }, []);
}
